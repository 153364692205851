import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import { memo, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { getAccount, setBattleType } from '~/Actions/ActionApp';
import BattleTypePicker from '~/Components/BattleTypePicker/BattleTypePicker';
import Charts from '~/Components/Charts/Charts';
import Hidden from '~/Components/Hidden/Hidden';
import Message from '~/Components/Message/Message';
import NotFound from '~/Components/NotFound/NotFound';
import Header from '~/Components/ProfileHeader/ProfileHeader';
import ProfileStatistics from '~/Components/ProfileStatistics/ProfileStatistics';
import ProfileStatisticsHeader from '~/Components/ProfileStatisticsHeader/ProfileStatisticsHeader';
import SeasonPicker from '~/Components/SeasonPicker/SeasonPicker';
import ShipsTable from '~/Components/ShipsTable/ShipsTable';
import { RootState, useAppDispatch, useAppSelector } from '~/store';
import { appActions } from '~/store/appSlice';
import { t } from '~/utils/localization';
import { getAvailableBattleTypes } from '~/utils/statistics';

import styles from './Profile.scss';

const stateSelector = (state: RootState) => {
  return {
    vehicleTypesIndexMap: state.app.vehicleTypesIndexMap,
    vehiclesMap: state.app.vehiclesMap,
    account: state.app.account,
    clanData: state.app.clanData,
    statisticsData: state.app.statisticsData,
    battleType: state.app.battleType,
    battleTypeRaw: state.app.battleTypeRaw,
    seasonPickerVisible: state.app.seasonPickerVisible,
    sortField: state.app.sortField,
    sortDirection: state.app.sortDirection,
    seasonNumber: state.app.seasonNumber,
    filters: state.app.filters,
    fetching: state.app.fetching,
  };
};

const Profile = () => {
  const params = useParams<LocationParams>();

  const dispatch = useAppDispatch();
  const {
    vehicleTypesIndexMap,
    vehiclesMap,
    account,
    statisticsData,
    battleType,
    battleTypeRaw,
    seasonPickerVisible,
    sortField,
    sortDirection,
    seasonNumber,
    filters,
    fetching,
  } = useAppSelector(stateSelector, equal);

  useEffect(() => {
    if (params.id && (!account || account.id !== params.id)) {
      dispatch(getAccount(params.id, params.access_code));
    }
    if (params.battletype && params.battletype !== battleType) {
      dispatch(setBattleType(params.battletype));
    }
    if (params.season && params.season !== seasonNumber) {
      dispatch(appActions.setSeasonNumber({ seasonNumber: params.season }));
    }
  }, [params]);

  if (fetching.account) {
    return null;
  }

  if (!account && fetching.account === false) {
    return <NotFound />;
  }

  const statBattleTypes = getAvailableBattleTypes(account, false);
  if (account && statBattleTypes.length > 0 && !statBattleTypes.includes(battleTypeRaw)) {
    return <Navigate to={`/statistics/${account.id}/${statBattleTypes[0]}/`} />;
  }

  const filtersMap: FiltersMap = {};

  filters.forEach((filterStr) => {
    const key = filterStr.split('#')[0];
    const val = filterStr.split('#')[1];

    if (filtersMap[key]) {
      filtersMap[key].push(val);
    } else {
      filtersMap[key] = [val];
    }
  });

  const filteredStatisticsData = statisticsData.filter((item) => {
    let enabled = !!vehiclesMap[item.id];

    if (item[battleType] && Object.keys(item[battleType]).length === 0 && !seasonPickerVisible) {
      enabled = false;
    }

    if (seasonPickerVisible) {
      const seasonId = parseInt(seasonNumber, 10);
      const data = get(item, `seasons.${seasonId}.${battleType}`, undefined);
      if (!data || Object.keys(data).length === 0) {
        enabled = false;
      }
    }
    return enabled;
  });

  const filteredShips: Array<TableShipStat> = filteredStatisticsData
    .filter((ship) => {
      const suiteableBattleType = ship[battleType] ? Object.keys(ship[battleType]).length > 0 : false;
      if (Object.keys(filtersMap).length > 0) {
        const shipInfo = vehiclesMap[ship.id];
        const filterResults = [];
        for (const key in filtersMap) {
          const checkingVal = get(shipInfo, key, undefined);
          const filterVal = get(filtersMap, `${key}.0`, undefined);

          if (
            (checkingVal && filtersMap[key].includes(`${checkingVal}`)) ||
            (key === 'title' && filterVal && `${checkingVal}`.toLowerCase().includes(`${filterVal}`.toLowerCase()))
          ) {
            filterResults.push(true);
          } else {
            filterResults.push(false);
          }
        }
        return suiteableBattleType && filterResults.filter((v) => !!v).length === filterResults.length;
      } else {
        return suiteableBattleType;
      }
    })
    .map((ship) => {
      return {
        id: ship.id,
        ...ship[battleType],
      };
    });

  const allShips: Array<TableShipStat> = filteredStatisticsData.map((ship) => {
    return {
      id: ship.id,
      ...ship[battleType],
    };
  });

  const sortedShips: Array<TableShipStat> = filteredShips.sort((a: TableShipStat, b: TableShipStat) => {
    const aShip = vehiclesMap[a.id];
    const bShip = vehiclesMap[b.id];

    if (aShip && bShip) {
      const aTypeIndex = vehicleTypesIndexMap[aShip.type.name];
      const bTypeIndex = vehicleTypesIndexMap[bShip.type.name];

      switch (sortField) {
        case 'class': {
          return bTypeIndex - aTypeIndex * sortDirection;
        }
        case 'nation': {
          const aName = get(aShip, 'nation.name', '');
          const bName = get(bShip, 'nation.name', '');
          return aName.localeCompare(bName) * sortDirection;
        }
        case 'level': {
          return (bShip.level - aShip.level) * sortDirection;
        }
        case 'name': {
          const aName = get(aShip, 'title', '') || '';
          const bName = get(bShip, 'title', '') || '';
          return aName.localeCompare(bName) * sortDirection;
        }
        case 'battles_count': {
          return (b.battles_count - a.battles_count) * sortDirection;
        }
        case 'premium_exp': {
          return (b.premium_exp - a.premium_exp) * sortDirection;
        }
        case 'wins': {
          return (b.wins - a.wins) * sortDirection;
        }

        default: {
          return (b.battles_count - a.battles_count) * sortDirection;
        }
      }
    } else {
      return 0;
    }
  });

  let statistics = get(account, `statistics[${battleType}]`, undefined);
  const seasonId = parseInt(seasonNumber, 10);
  const seasonData = get(account, `statistics.seasons[${seasonId}]`, undefined);

  if (seasonPickerVisible && seasonData && seasonData[battleType]) {
    statistics = seasonData[battleType];
  }

  const hasStatistics = !!statistics && Object.keys(statistics).length > 0;
  const isHidden = account ? account.hidden_profile : false;

  if (isHidden) {
    return <Hidden />;
  }

  return (
    <>
      <div className={styles.headerWrapper}>
        <Header />
      </div>
      <div className={styles.pickerRow}>
        <BattleTypePicker withDivisionPicker />
        {seasonPickerVisible && (
          <div className={styles.seasonPicker}>
            <SeasonPicker />
          </div>
        )}
      </div>
      {hasStatistics && (
        <>
          <ProfileStatisticsHeader statistics={statistics} />
          <ProfileStatistics statistics={statistics} />
          <Charts />
          <ShipsTable sortedShips={sortedShips} allShips={allShips} />
        </>
      )}
      {account && !hasStatistics && (
        <Message
          text={t(
            'You will be able to view this players battle statistics after the player has fought their first battle.',
          )}
        />
      )}
    </>
  );
};

export default memo(Profile);
