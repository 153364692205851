import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { FETCHING_KEYS, PERSISTED_DATA_KEY, SEASONAL_BATTLE_TYPES } from '~/constants';
import defaultStatistics from '~/defaultStatistics';
import preloaded from '~/preloaded';
import { getBattleType } from '~/utils/glossary';

export interface State {
  isInviteSendMap: IsInviteSendMap;
  filters: Array<string>;
  authSpaId?: number;
  hasOwnClan: boolean;
  ownClanRole?: RoleName;
  achievements: AchievementsAccountStatisticsAchievements;
  encyclopedia: GlossaryQuery;
  fetching: FetchingMap;
  isReady: boolean;
  spaId?: string;
  account?: Account;
  clanData?: ClanData;
  statisticsData: Array<TableShip>;
  battleTypeRaw: BattleTypeName;
  battleType: StatBattleType;
  division: DivisionName;
  achievementsSortOrder: AchievementsSortOrder;
  seasonPickerVisible: boolean;
  seasonNumber?: string;
  sortField: TableSortField;
  sortDirection: SortDirection;
  suggestions: Suggestions;
  searchResults: SearchResults;
  showMoreButton: boolean;
  statistics: BattleStat;
  shipStatistics: BattleStat;
  vehiclesMap: VehiclesMap;
  vehicleTypesIndexMap: VehicleTypesIndexMap;
  vehicleTypesMap: VehicleTypesMap;
  dollsMap: DollsMap;
  nationsMap: NationsMap;
  battleTypesMap: BattleTypesMap;
  nationStatisticsMap: NationsStatisticsMap;
  vehicleTypeStatisticsMap: VehicleTypeStatisticsMap;
  vehicleLevelStatisticsMap: VehicleLevelStatisticsMap;
  isShipTableHeaderSticky: boolean;
  linkFetching: boolean;
  shipFilterByTitleValue: string;
}

const persistedStateStr: string = sessionStorage.getItem(PERSISTED_DATA_KEY);
const persistedState: PersistedState = persistedStateStr ? JSON.parse(persistedStateStr) : undefined;

const initialState: State = {
  isInviteSendMap: persistedState ? persistedState.isInviteSendMap : {},
  filters: [],
  authSpaId: get(preloaded, 'account.id', undefined),
  hasOwnClan: false,
  ownClanRole: undefined,
  achievements: {},
  encyclopedia: {},
  fetching: {
    clan: true,
    statistics: true,
    account: true,
  },
  isReady: false,
  spaId: undefined,
  account: undefined,
  clanData: undefined,
  statisticsData: [],
  battleTypeRaw: 'pvp',
  battleType: 'pvp',
  division: undefined,
  achievementsSortOrder: 'default',
  seasonPickerVisible: false,
  seasonNumber: undefined,
  sortField: 'battles_count',
  sortDirection: 1,
  suggestions: [],
  searchResults: [],
  showMoreButton: true,
  statistics: defaultStatistics,
  shipStatistics: defaultStatistics,
  vehiclesMap: {},
  vehicleTypesIndexMap: {},
  vehicleTypesMap: {},
  dollsMap: {},
  nationsMap: {},
  battleTypesMap: {},
  nationStatisticsMap: {},
  vehicleTypeStatisticsMap: {},
  vehicleLevelStatisticsMap: {},
  isShipTableHeaderSticky: false,
  linkFetching: false,
  shipFilterByTitleValue: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    dropAccount(state) {
      state.account = undefined;
      state.fetching.account = true;
    },
    updateVisibilitySettings(state, action: PayloadAction<VisibilitySettings>) {
      if (state.account) {
        state.account.visibility_settings = action.payload;
      }
    },
    setAccessCodeFetching(state, action: PayloadAction<boolean>) {
      state.linkFetching = action.payload;
    },
    setAccessCodeToAccount(state, action: PayloadAction<string>) {
      if (state.account && state.account.visibility_settings) {
        state.account.visibility_settings.access_code = action.payload;
      }
    },
    searchInit(state) {
      state.fetching = {
        account: false,
        clan: false,
        statistics: false,
      };
    },
    setSeasonNumber(state, action: PayloadAction<{ seasonNumber: string }>) {
      state.seasonNumber = action.payload.seasonNumber;
    },
    setSortField(state, action: PayloadAction<{ sortField: CellSelector; sortDirection: SortDirection }>) {
      state.sortField = action.payload.sortField;
      state.sortDirection = action.payload.sortDirection;
    },
    getGlossaryData(
      state,
      action: PayloadAction<{
        encyclopedia: GlossaryQuery;
        vehiclesMap: VehiclesMap;
        vehicleTypesIndexMap: VehicleTypesIndexMap;
        vehicleTypesMap: VehicleTypesMap;
        dollsMap: DollsMap;
        nationsMap: NationsMap;
        battleTypesMap: BattleTypesMap;
      }>,
    ) {
      state.encyclopedia = action.payload.encyclopedia;
      state.vehiclesMap = action.payload.vehiclesMap;
      state.vehicleTypesIndexMap = action.payload.vehicleTypesIndexMap;
      state.vehicleTypesMap = action.payload.vehicleTypesMap;
      state.dollsMap = action.payload.dollsMap;
      state.nationsMap = action.payload.nationsMap;
      state.battleTypesMap = action.payload.battleTypesMap;
    },
    setBattleType(state, action: PayloadAction<{ battleType: BattleTypeName }>) {
      state.battleTypeRaw = action.payload.battleType;
      state.battleType = getBattleType(action.payload.battleType, state.division);
      state.seasonPickerVisible = SEASONAL_BATTLE_TYPES.includes(action.payload.battleType);
    },
    setDivision(state, action: PayloadAction<{ division: DivisionName }>) {
      state.battleType = getBattleType(state.battleTypeRaw, action.payload.division);
      state.division = action.payload.division;
    },
    setAchievementsSortOrder(state, action: PayloadAction<{ achievementsSortOrder: AchievementsSortOrder }>) {
      state.achievementsSortOrder = action.payload.achievementsSortOrder;
    },
    setStatisticsDataFetching(state, action: PayloadAction<{ isFetching: boolean }>) {
      state.fetching[FETCHING_KEYS.STATISTICS] = action.payload.isFetching;
    },
    setStatisticsData(state, action: PayloadAction<{ statisticsData: TableShip[] }>) {
      state.statisticsData = action.payload.statisticsData;
    },
    setClanFetching(state, action: PayloadAction<{ isFetching: boolean }>) {
      state.fetching[FETCHING_KEYS.CLAN] = action.payload.isFetching;
    },
    setClanData(state, action: PayloadAction<{ clanData?: ClanData; isOwnClan?: boolean }>) {
      const { isOwnClan, clanData } = action.payload;
      if (isOwnClan && clanData?.clan_id) {
        state.hasOwnClan = true;
        state.ownClanRole = clanData.role as RoleName;
      }
      state.clanData = clanData;
    },
    setAccountFetching(state, action: PayloadAction<{ isFetching: boolean }>) {
      state.fetching[FETCHING_KEYS.ACCOUNT] = action.payload.isFetching;
    },
    setAccount(state, action: PayloadAction<{ account?: Account; id?: string }>) {
      state.account = action.payload.account;
      state.spaId = action.payload.id;
    },
    setAutocompleteSuggestions(state, action: PayloadAction<{ suggestions: Suggestions }>) {
      state.suggestions = action.payload.suggestions;
    },
    setSearchResults(state, action: PayloadAction<{ searchResults: SearchResults; append: boolean }>) {
      const showMoreButton = action.payload.searchResults.length > 0;
      state.searchResults = action.payload.append
        ? [...state.searchResults, ...action.payload.searchResults]
        : action.payload.searchResults;
      state.showMoreButton = showMoreButton;
    },
    setShipStatistics(state, action: PayloadAction<{ statistics: BattleStat }>) {
      state.shipStatistics = action.payload.statistics;
    },
    setAccountAchievements(state, action: PayloadAction<{ achievements: AchievementsAccountStatisticsAchievements }>) {
      state.achievements = action.payload.achievements;
    },
    setChartsData(
      state,
      action: PayloadAction<{
        nationStatisticsMap: NationsStatisticsMap;
        vehicleTypeStatisticsMap: VehicleTypeStatisticsMap;
        vehicleLevelStatisticsMap: VehicleLevelStatisticsMap;
      }>,
    ) {
      state.nationStatisticsMap = action.payload.nationStatisticsMap;
      state.vehicleTypeStatisticsMap = action.payload.vehicleTypeStatisticsMap;
      state.vehicleLevelStatisticsMap = action.payload.vehicleLevelStatisticsMap;
    },
    toggleFilter(state, action: PayloadAction<{ filters: string[] }>) {
      state.filters = [...action.payload.filters];
    },
    setShipTableIsSticky(state, action: PayloadAction<{ isSticky: boolean }>) {
      state.isShipTableHeaderSticky = action.payload.isSticky;
    },
    setInvitedId(state, action: PayloadAction<{ id: string }>) {
      state.isInviteSendMap[action.payload.id] = true;
    },
    setShipFilterByTitleValue(state, action: PayloadAction<{ shipFilterByTitleValue: string }>) {
      state.shipFilterByTitleValue = action.payload.shipFilterByTitleValue;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
