import { ClanName, Interpolate, MemberName } from '@wg/wows-react-uikit';
import classnames from 'classnames';
import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import moment from 'moment';
import { Sticky } from 'react-sticky';

import DogTag from '~/Components/DogTag/DogTag';
import { Col, Container, Row } from '~/Components/Grid/Grid';
import InviteButton from '~/Components/InviteButton/InviteButton';
import ProfilePrivacy from '~/Components/ProfilePrivacy/ProfilePrivacy';
import SearchPanel from '~/Components/SearchPanel/SearchPanel';
import { RootState, useAppSelector } from '~/store';
import { isInGame } from '~/utils';
import { t } from '~/utils/localization';
import { formatName } from '~/utils/players';
import { getRoles, permissions } from '~/utils/roles';

import styles from './ProfileHeader.scss';

const isSettingsVisible = window.preloaded.is_visible_settings_show;

export interface ProfileHeaderProps {
  noSearch?: boolean;
}

const stateSelector = (state: RootState) => {
  return {
    ownClanRole: state.app.ownClanRole,
    hasOwnClan: state.app.hasOwnClan,
    authSpaId: state.app.authSpaId,
    spaId: state.app.spaId,
    account: state.app.account,
    clanData: state.app.clanData,
    isShipTableHeaderSticky: state.app.isShipTableHeaderSticky,
  };
};

const ProfileHeader = (props: ProfileHeaderProps) => {
  const { noSearch } = props;
  const { account, clanData, isShipTableHeaderSticky, authSpaId, spaId, hasOwnClan, ownClanRole } = useAppSelector(
    stateSelector,
    equal,
  );

  const registrationDate = account ? moment(account.created_at * 1000).format('DD.MM.YYYY') : '';
  const name = formatName(account ? account.name : '');
  const roleName = clanData ? clanData.role : ('' as RoleName);
  const color = get(clanData, 'clan.color', undefined);
  const hexColor = color ? `#${color.toString(16)}` : '#fff';
  const isHidden = account ? account.hidden_profile : false;
  const tag = get(clanData, 'clan.tag', undefined);
  const clanName = get(clanData, 'clan.name', undefined);
  const clanId = get(clanData, 'clan_id', undefined);

  const topOffset = isInGame ? 0.1 : 37;
  const isMyProfile = authSpaId && `${authSpaId}` === spaId;

  const roles = getRoles(t);
  const role = get(roles, roleName, undefined) as Role;
  const ownRole = get(roles, ownClanRole, undefined) as Role;

  const hasPermission = ownRole && ownRole.permissions.includes(permissions.INVITE);

  return (
    <Sticky topOffset={topOffset}>
      {({ style, isSticky }) => {
        const stickyStyle: any = style;
        const wrapper = classnames(styles.wrapper, {
          [styles.stickyWrapper]: isSticky,
          [styles.stickyWrapperShadow]: isSticky && !isShipTableHeaderSticky,
        });
        const dogtag = classnames(styles.dogtag, {
          [styles.dogtagSmall]: isSticky,
        });

        return (
          <div
            style={{
              ...stickyStyle,
              zIndex: 900,
              top: isSticky ? `${topOffset}px` : undefined,
            }}
          >
            <Container className={wrapper} id={'profileHeader'}>
              <Row className={styles.rowWrapper}>
                <Col size={6}>
                  <div className={dogtag}>
                    {!isHidden ? <DogTag clanTag={tag} small={isSticky} /> : null}
                    <div className={styles.nameWrapper}>
                      <div className={styles.name}>{name}</div>
                      {isHidden ? null : (
                        <div className={styles.registration}>
                          <Interpolate str={t('Registration date: %(date)s')} date={registrationDate} />
                        </div>
                      )}
                      {account && account.visibility_settings && isSettingsVisible && (
                        <ProfilePrivacy
                          id={account.id}
                          visibilitySettings={account.visibility_settings}
                          isClanMember={clanId && clanName}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col size={noSearch ? 6 : 3}>
                  <div className={styles.clanData}>
                    {tag && clanName && <ClanName clanColor={hexColor} clanName={clanName} clanTag={tag} />}
                    {!clanId && !isMyProfile && hasOwnClan && hasPermission && <InviteButton />}
                    <MemberName isInline={false} name={role ? role.localized_name : ''} role={role ? role.name : ''} />
                  </div>
                </Col>
                {noSearch ? null : (
                  <Col size={3} className={styles.searchWrapper}>
                    <div className={styles.searchWrapperHeight}>
                      <SearchPanel />
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        );
      }}
    </Sticky>
  );
};

export default ProfileHeader;
