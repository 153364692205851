import equal from 'fast-deep-equal/react';
import debounce from 'lodash/throttle';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUsersAutocomplete } from '~/Actions/ActionApp';
import { navigateToProfile, navigateToSearchResults } from '~/Actions/ActionNavigation';
import Autocomplete from '~/Components/Autocomplete/Autocomplete';
import { DEBOUNCE_DELAY, NavigationPlaces } from '~/constants';
import { useMount } from '~/hooks/index';
import { RootState, useAppDispatch, useAppSelector } from '~/store';
import { searchToObject } from '~/utils';
import { t } from '~/utils/localization';
import { formatName } from '~/utils/players';

import styles from './SearchPanel.scss';

interface SearchPanelInterfaceProps {
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
}

const stateSelector = (state: RootState) => {
  return {
    suggestions: state.app.suggestions,
  };
};

const SearchPanel: React.FC<SearchPanelInterfaceProps> = (props) => {
  const { onChange, onSearch } = props;

  const navigate = useNavigate();
  const state = useAppSelector(stateSelector, equal);
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState<string>('');
  const { suggestions } = state;

  const onChangeCallback = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  const updateSuggestions = (query: string) => {
    dispatch(getUsersAutocomplete(query));
  };

  const updateSuggestionsDebounced = useCallback(debounce(updateSuggestions, DEBOUNCE_DELAY), []);

  const onAutocompleteChangeEvent = useCallback((value: string) => {
    setSearchValue(value);
    updateSuggestionsDebounced(value);
    onChangeCallback(value);
  }, []);

  useMount(() => {
    const queryParams = searchToObject();
    if (queryParams.query) {
      setSearchValue(`${queryParams.query}`);
    }
  });

  const onAutocompleteSearchEvent = (query: string) => {
    dispatch(navigateToSearchResults(navigate, query, NavigationPlaces.SEARCH));
    if (onSearch) {
      onSearch(query);
    }
  };

  const onAutocompleteSelectEvent = (index: number) => {
    const searchValue = formatName(suggestions[index].name);
    const spaId = `${suggestions[index].spa_id}`;
    setSearchValue(searchValue);
    dispatch(navigateToProfile(navigate, spaId, NavigationPlaces.SEARCH));
    onChangeCallback(searchValue);
  };

  const autocompleteRenderItem = (index: number) => {
    return <div>{formatName(suggestions[index].name)}</div>;
  };

  const onClearButtonClickEvent = () => {
    onAutocompleteSearchEvent('');
  };

  return (
    <div className={styles.wrapper}>
      <Autocomplete
        placeholder={t('Enter player name')}
        value={searchValue}
        onSelect={onAutocompleteSelectEvent}
        onChange={onAutocompleteChangeEvent}
        renderItem={autocompleteRenderItem}
        onSearch={onAutocompleteSearchEvent}
        onClearButtonClick={onClearButtonClickEvent}
        suggestions={suggestions}
      />
    </div>
  );
};

export default SearchPanel;
