import { NotificationManager } from '@wg/wows-react-uikit';

import vortexClient from '~/clients/vortex';
import { VORTEX_ACCOUNTS } from '~/constants';
import { IAppDispatch, IAppThunk } from '~/store';
import { appActions } from '~/store/appSlice';

export const setVisibility =
  (id: string, visibilitySettings: VisibilitySettings): IAppThunk =>
  (dispatch: IAppDispatch) => {
    dispatch(appActions.setAccountFetching({ isFetching: true }));
    const url = `${VORTEX_ACCOUNTS}/${id}/visibility/`; //274358
    const data = JSON.stringify(visibilitySettings);
    vortexClient
      .post<VisibilitySettingsResponse>(url, data)
      .then((r) => {
        const visibilitySettings = r.data.data;
        dispatch(appActions.updateVisibilitySettings(visibilitySettings));
        NotificationManager.sendWebNotification({
          message: window.notificationMessages.updateVisibilitySetting,
        });
      })
      .catch((err: any) => {
        console.log(err);
        NotificationManager.sendWebNotification({
          message: window.notificationMessages.errorUpdatingVisibilitySettings,
        });
      })
      .finally(() => {
        dispatch(appActions.setAccountFetching({ isFetching: false }));
      });
  };

export const AccessCodeLink =
  (id: string): IAppThunk =>
  (dispatch: IAppDispatch) => {
    dispatch(appActions.setAccessCodeFetching(true));
    const url = `${VORTEX_ACCOUNTS}/${id}/access_code/`;

    vortexClient
      .get<AccessCodeResponse>(url)
      .then((r) => {
        const access_code = r.data.data.access_code;
        dispatch(appActions.setAccessCodeToAccount(access_code));
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(appActions.setAccessCodeFetching(false));
      });
  };
