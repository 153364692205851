import { Label } from '@wg/wows-react-uikit';

import Achievement from '~/Components/Achievement/Achievement';
import {
  getAchievementsByGroupNameAndSubGroupName,
  isAchievementReceived,
  sortAchievement,
} from '~/utils/achievements';

import styles from './AchievementSubGroup.scss';

export interface AchievementSubGroup_Props extends AchievementsSubGroup {
  group_id: any;
  accountAchievements: AchievementsAccountStatisticsAchievements;
  allAchievements: Array<Achievements>;
  achievementsSortOrder: AchievementsSortOrder;
}

const AchievementSubGroup = (props: AchievementSubGroup_Props) => {
  const { id, title, group_id, accountAchievements, allAchievements, achievementsSortOrder } = props;

  const filterAchievementByGroupIdAndSubGroupId = (groupId: string, subGroupId: string) => {
    const filteredAchievements = getAchievementsByGroupNameAndSubGroupName(groupId, subGroupId, allAchievements);
    return sortAchievement(filteredAchievements, accountAchievements, achievementsSortOrder, groupId, subGroupId);
  };
  const achievementsList = filterAchievementByGroupIdAndSubGroupId(group_id, id);
  const renderAchievements = (achievement: Achievements, index: number) => {
    const isReceived = isAchievementReceived(achievement, accountAchievements);
    const accountAchievement = isReceived ? accountAchievements[achievement.id] : null;
    return (
      <div className={styles.row}>
        <Achievement
          {...achievement}
          isReceived={isReceived}
          count={isReceived ? accountAchievement.count : undefined}
          lastTime={isReceived ? accountAchievement.last_time : undefined}
          shipId={isReceived ? accountAchievement.ship_id : undefined}
          key={`AccountAchievement-${group_id}-${id}-${achievement.id}-${index}`}
        />
      </div>
    );
  };

  if (achievementsList.length > 0) {
    return (
      <div>
        <div className={styles.achievementSubGroupTitle}>
          <Label>{title}</Label>
        </div>
        <div className={styles.subGroupContent}>
          {achievementsList.map((achievement, index) => {
            return renderAchievements(achievement, index);
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default AchievementSubGroup;
