import { memo } from 'react';

import Message from '~/Components/Message/Message';
import ProfileHeader from '~/Components/ProfileHeader/ProfileHeader';
import { t } from '~/utils/localization';

import styles from './Hidden.scss';

const Hidden = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <ProfileHeader noSearch />
        <Message text={t('This users profile is private.')} />
      </div>
    </div>
  );
};

export default memo(Hidden);
