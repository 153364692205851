import { Interpolate } from '@wg/wows-react-uikit';
import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Select from '~/Components/Select/Select';
import { RootState, useAppSelector } from '~/store';
import { t } from '~/utils/localization';

import styles from './SeasonPicker.scss';

interface SeasonItem {
  title: string;
  value: string;
  seasonNumber: number;
}

const stateSelector = (state: RootState) => {
  return {
    account: state.app.account,
    seasonNumber: state.app.seasonNumber,
    battleType: state.app.battleType,
    division: state.app.division,
  };
};

type Seasons = {
  [key: string]: Season;
};

const SeasonPicker = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const navigate = useNavigate();
  const params = useParams<LocationParams>();
  const { account, seasonNumber, battleType } = useAppSelector(stateSelector, equal);

  const allSeasons: Seasons = get(account, 'statistics.seasons', {});
  const seasons: Seasons = {};

  for (const key in allSeasons) {
    const season = allSeasons[key];
    if (Object.keys(season).includes(battleType)) {
      seasons[key] = season;
    }
  }

  const seasonItems: Array<SeasonItem> = [];

  for (const key in seasons) {
    let seasonNumber = parseInt(key, 10);

    if (parseInt(key, 10) > 1000) {
      seasonNumber = parseInt(key, 10) % 1000;
    }

    const title = t('Season %(seasonNumber)s ');

    // if (seasonNumber >= 100) {
    //     title = t(`Ранговый спринт %(seasonNumber)s`)
    //     seasonNumber = seasonNumber - 100
    // }

    if (seasonNumber < 100) {
      seasonItems.push({
        title: title,
        value: key,
        seasonNumber: seasonNumber,
      });
    }
  }

  let selectedSeasonIndex = 0;
  seasonItems.forEach((seasonItem, index) => {
    if (seasonItem.value === seasonNumber) {
      selectedSeasonIndex = index;
    }
  });

  if (selectedSeasonIndex !== selectedIndex) {
    setSelectedIndex(selectedSeasonIndex);
  }

  const renderValue = () => {
    const itemData = seasonItems[selectedIndex];
    return (
      <div className={styles.value}>
        <Interpolate str={itemData.title} seasonNumber={itemData.seasonNumber} />
      </div>
    );
  };

  const renderItem = (index: number) => {
    const itemData = seasonItems[index];
    return (
      <div className={styles.item}>
        <Interpolate str={itemData.title} seasonNumber={itemData.seasonNumber} />
      </div>
    );
  };

  const onSelectHandler = (index: number) => {
    setSelectedIndex(index);
    const value = seasonItems[index] ? seasonItems[index].value : undefined;
    if (value && params.id && params.battletype) {
      navigate(`/statistics/${params.id}/${params.battletype}/${seasonItems[index].value}`);
    }
  };

  useEffect(() => {
    if (seasonItems[0]) {
      onSelectHandler(0);
    }
  }, [battleType]);

  if (seasonItems.length === 0) {
    return null;
  }

  return (
    <Select
      maxItemsCount={5}
      items={seasonItems.map((item) => item.value)}
      selectedIndex={selectedSeasonIndex}
      onSelect={onSelectHandler}
      renderValue={renderValue}
      renderItem={renderItem}
    />
  );
};

export default SeasonPicker;
