import { Action, Middleware } from '@reduxjs/toolkit';

import { PERSISTED_DATA_KEY } from '~/constants';
import { RootState } from '~/store';
import { appActions } from '~/store/appSlice';

const allowedActionTypes: Array<string> = [appActions.setInvitedId.type];

const persistMiddleware: Middleware<{}, RootState> = (store) => (next) => (action: Action) => {
  const result = next(action);
  if (allowedActionTypes.includes(action.type)) {
    const state = store.getState();
    const persistedState: PersistedState = {
      isInviteSendMap: state.app.isInviteSendMap,
    };
    sessionStorage.setItem(PERSISTED_DATA_KEY, JSON.stringify(persistedState));
  }
  return result;
};

export default persistMiddleware;
