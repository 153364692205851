import equal from 'fast-deep-equal/react';
import { get as lGet } from 'lodash/fp';
import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getAccount } from '~/Actions/ActionApp';
import AchievementGroup from '~/Components/AchievementGroup/AchievementGroup';
import { Col, Container, Row } from '~/Components/Grid/Grid';
import Hidden from '~/Components/Hidden/Hidden';
import NotFound from '~/Components/NotFound/NotFound';
import Header from '~/Components/ProfileHeader/ProfileHeader';
import Select from '~/Components/Select/Select';
import { ACHIEVEMENT_GROUP_TYPES, ACHIEVEMENT_SORT_ORDER } from '~/constants';
import { useWillMount } from '~/hooks';
import { RootState, useAppDispatch, useAppSelector } from '~/store';
import { appActions } from '~/store/appSlice';
import { t } from '~/utils/localization';

import styles from './Achievements.scss';

export interface AchievementsProps {
  noNavigation?: boolean;
}

const stateSelector = (state: RootState) => {
  const achievements = state.app.encyclopedia.achievements || [];
  const all_achievements_groups = state.app.encyclopedia.achievementsGroups || [];
  return {
    account: state.app.account,
    allAchievements: achievements
      .filter((e) => {
        return e.grouping.group !== ACHIEVEMENT_GROUP_TYPES.NOT_USED;
      })
      .sort((a, b) => {
        return parseInt(b.id) - parseInt(a.id);
      }),
    allAchievementGroups: [...all_achievements_groups].sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    }),
    achievements: state.app.achievements,
    fetching: state.app.fetching,
    achievementsSortOrder: state.app.achievementsSortOrder,
  };
};

const Achievements = (props: AchievementsProps) => {
  const params = useParams<LocationParams>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const state = useAppSelector(stateSelector, equal);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { account, achievements, allAchievements, allAchievementGroups, fetching, achievementsSortOrder } = state;

  useWillMount(() => {
    if (params.id && !account) {
      dispatch(getAccount(params.id, params.access_code));
    }
  });

  useEffect(() => {
    if (params.sortorder && params.sortorder !== achievementsSortOrder) {
      dispatch(appActions.setAchievementsSortOrder({ achievementsSortOrder: params.sortorder }));
    }
  }, [params]);

  if (params.id && account && account.id !== params.id) {
    dispatch(getAccount(params.id, params.access_code));
  }

  if (fetching.account) {
    return null;
  }

  if (!account && fetching.account === false) {
    return <NotFound />;
  }

  const isHidden = account ? account.hidden_profile : false;

  if (isHidden) {
    return <Hidden />;
  }

  const achievementsSortOrderTitles = {
    default: t('Default'),
    date: t('By date'),
    count: t('By number'),
  };

  const achievementsSortOrders = ACHIEVEMENT_SORT_ORDER.map((achievementsSortOrderListItem) => {
    return {
      title: achievementsSortOrderTitles[achievementsSortOrderListItem.id],
      value: achievementsSortOrderListItem.id,
    };
  });

  let achievementsSortOrderIndex = 0;
  achievementsSortOrders.forEach((achievementsSortOrderItem, index) => {
    if (achievementsSortOrderItem.value === achievementsSortOrder) {
      achievementsSortOrderIndex = index;
    }
  });

  if (achievementsSortOrderIndex !== selectedIndex) {
    setSelectedIndex(achievementsSortOrderIndex);
  }

  const onSelectHandler = (index: number) => {
    setSelectedIndex(index);
    const value = lGet([index, 'value'], achievementsSortOrders);
    if (props.noNavigation) {
      dispatch(appActions.setAchievementsSortOrder({ achievementsSortOrder: value as AchievementsSortOrder }));
    } else {
      if (value && params.id) {
        navigate(`/achievements/${params.id}/${value}`);
      }
    }
  };

  const renderValue = () => {
    const title = achievementsSortOrders[selectedIndex] ? achievementsSortOrders[selectedIndex].title : ' ';
    return <div className={styles.value}>{title}</div>;
  };

  const renderItem = (index: number) => {
    return achievementsSortOrders[index].title;
  };

  return (
    <React.Fragment>
      <div className={styles.header}>
        <Header />
      </div>
      <Container>
        <Row>
          <Col size={0} />
          <Col size={3} className={styles.pickerRow}>
            <Select
              maxItemsCount={achievementsSortOrders.length}
              items={achievementsSortOrders.map((item) => item.value)}
              selectedIndex={achievementsSortOrderIndex}
              onSelect={onSelectHandler}
              renderValue={renderValue}
              renderItem={renderItem}
            />
          </Col>
        </Row>
      </Container>
      <div>
        {allAchievementGroups.map((achievementGroup) => {
          return (
            <AchievementGroup
              {...achievementGroup}
              allAchievements={allAchievements}
              accountAchievements={achievements}
              achievementsSortOrder={achievementsSortOrder}
              key={`AchievementGroup-${achievementGroup.id}`}
            />
          );
        })}
      </div>
      <div className={styles.allGroupAchievementsBottomIndent} />
    </React.Fragment>
  );
};

export default memo(Achievements);
