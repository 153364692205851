import { Processing } from '@wg/wows-react-uikit';
import equal from 'fast-deep-equal/react';

import { RootState, useAppSelector } from '~/store';

const stateSelector = (state: RootState) => {
  return {
    fetching: state.app.fetching,
  };
};

const Spinner = () => {
  const { fetching } = useAppSelector(stateSelector, equal);

  let isFetching = false;

  for (const key in fetching) {
    if (!isFetching && fetching[key]) {
      isFetching = true;
    }
  }

  return <Processing isFetching={isFetching} />;
};

export default Spinner;
