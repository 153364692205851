import { memo } from 'react';

import { Container } from '~/Components/Grid/Grid';
import Statistics from '~/Components/Statistics/Statistics';
import { getStatisticsData } from '~/utils/statistics';

export interface ProfileStatisticsState {
  account?: Account;
  battleType: StatBattleType;
  statistics: BattleStat;
  seasonPickerVisible: boolean;
  seasonNumber?: string;
}

const ProfileStatistics = (props: any) => {
  const data = getStatisticsData(props.statistics);

  return (
    <Container>
      <Statistics data={data} />
    </Container>
  );
};

export default memo(ProfileStatistics);
