import { NavigateFunction } from 'react-router-dom';

import { dropAccount, dropFilters, getUsersSearch } from '~/Actions/ActionApp';
import { DWH_EVENTS, Pages, ROUTES } from '~/constants';
import preloaded from '~/preloaded';
import { IAppDispatch, IAppThunk, RootState } from '~/store';
import dwhExport from '~/utils/dwhExport';

export const navigateToProfile =
  (navigate: NavigateFunction, id: string, place?: string): IAppThunk =>
  (dispatch: IAppDispatch) => {
    dispatch(dropFilters());
    dispatch(dropAccount());
    dwhExport.send(DWH_EVENTS.OPEN_PAGE, {
      name: Pages.STATISTICKS,
      from: place,
      profile_type: (preloaded.account && preloaded.account.id === +id) || !id ? 'own' : 'other',
    });
    navigate(`/statistics/${id}`);
  };

export const navigateToSearchResults =
  (navigate: NavigateFunction, query: string, place?: string): IAppThunk =>
  (dispatch: IAppDispatch) => {
    dispatch(getUsersSearch(query));
    dwhExport.send(DWH_EVENTS.OPEN_PAGE, { name: Pages.SEARCH, from: place });
    navigate(`${ROUTES.ROOT}?query=${query}`);
  };

export const navigateToRoute =
  (navigate: NavigateFunction, route: string, clb: () => void): IAppThunk =>
  (_: IAppDispatch, getState: () => RootState) => {
    const state = getState();
    const { spaId } = state.app;

    if (route == '/') {
      navigate(route);
    } else {
      navigate(`${route}/${spaId}`);
    }
    clb();
  };
