import equal from 'fast-deep-equal/react';
import { useEffect, useState } from 'react';

import { setDivision } from '~/Actions/ActionApp';
import Select from '~/Components/Select/Select';
import { RootState, useAppDispatch, useAppSelector } from '~/store';
import { t } from '~/utils/localization';
import { getAvailableDivisions } from '~/utils/statistics';

import { getDivisionsFromBattletype } from './config';
import styles from './DivisionPicker.scss';

export interface DivisionPickerProps {
  noNavigation?: boolean;
  allTypes?: boolean;
  availableStatsBattleTypes: Array<StatBattleType>;
}

const stateSelector = (state: RootState) => {
  return {
    battleTypeRaw: state.app.battleTypeRaw,
  };
};

const DivisionPicker = (props: DivisionPickerProps) => {
  const { battleTypeRaw } = useAppSelector(stateSelector, equal);
  const dispatch = useAppDispatch();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const allDivisions: DivisionName[] = ['', 'solo', 'div2', 'div3'];
  const availableDivisions: DivisionName[] = getAvailableDivisions(
    battleTypeRaw,
    allDivisions,
    props.availableStatsBattleTypes,
  );

  const Divisions = getDivisionsFromBattletype(battleTypeRaw as BattleTypeNameWithDivisions, t).filter((division) =>
    availableDivisions.includes(division.value),
  );

  const renderValue = () => {
    const title = Divisions[selectedIndex] ? Divisions[selectedIndex].title : ' ';
    return <div className={styles.value}>{title}</div>;
  };

  const renderItem = (index: number) => {
    return Divisions[index].title;
  };

  const onSelectHandler = (index: number) => {
    setSelectedIndex(index);
    const value = Divisions[index] ? Divisions[index].value : undefined;
    dispatch(setDivision(value as DivisionName));
  };

  useEffect(() => {
    onSelectHandler(0);
  }, [battleTypeRaw]);

  if (Divisions === undefined || Divisions.length === 0) {
    return null;
  }

  return (
    <Select
      maxItemsCount={Divisions.length}
      items={Divisions.map((item) => item.value)}
      selectedIndex={selectedIndex}
      onSelect={onSelectHandler}
      renderValue={renderValue}
      renderItem={renderItem}
    />
  );
};

export default DivisionPicker;
