import { gql } from '@apollo/client';

const query = gql`
  query getGlossData($lang: String) {
    version
    nations(lang: $lang) {
      name
      title
      color
      icons {
        small
        tiny
      }
    }
    dogTagComponents(lang: $lang) {
      id
      type
      color
      isColorizable
      showClanTag
      clanTag {
        x
        y
        fontColor
      }
      icons {
        medium
      }
      textureData {
        id
        background {
          medium
        }
        border {
          medium
        }
      }
    }
    achievements(lang: $lang) {
      id
      icons {
        default
        inactive
        normal
      }
      title
      description
      enabled
      multiple
      hidden
      typeTitle
      battleRestriction
      receivingRestriction
      battleTypes {
        id
      }
      isProgress
      maxProgress
      grouping {
        sortOrder
        sortOrderInGroup
        group
        subgroup
      }
      type
    }
    nations(lang: $lang) {
      name
      title
      color
    }
    vehicleTypes(lang: $lang) {
      name
      title
      icons {
        default
        special
        normal
        elite
        premium
      }
    }
    battleTypes(lang: $lang) {
      id
      icons {
        default
      }
      title
      name
      dossierName
    }
    achievementsGroups(lang: $lang) {
      id
      title
      sortOrder
      subgroups {
        id
        title
        sortOrder
      }
    }
    vehicles(lang: $lang) {
      isPremium
      isSpecial
      id
      title
      titleShort
      icons {
        small
      }
      type {
        name
        title
        titleShort
      }
      level
      nation {
        name
        title
        color
        icons {
          small
          tiny
        }
      }
      nationName
    }
  }
`;

export default query;
