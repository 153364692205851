import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import { memo } from 'react';

import { RootState, useAppSelector } from '~/store';

import styles from './ShipClass.scss';

const vehicleTypesMapSelector = (state: RootState) => state.app.vehicleTypesMap;

export interface ShipClass_Props {
  type: string;
}

const ShipClass = (props: ShipClass_Props) => {
  const nationsMap = useAppSelector(vehicleTypesMapSelector, equal);
  const { type } = props;

  const iconUrl = get(nationsMap, `[${type}].icons.default`, undefined);

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: `url(${iconUrl})`,
      }}
    />
  );
};

export default memo(ShipClass, equal);
