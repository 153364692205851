import styles from './Message.scss';

export interface MessageProps {
  text: string;
}

const Message = ({ text }: MessageProps) => {
  return <div className={styles.wrapper}>{text}</div>;
};

export default Message;
