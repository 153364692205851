import AchievementSubGroup from '~/Components/AchievementSubGroup/AchievementSubGroup';
import Spoiler from '~/Components/Spoiler/Spoiler';
import { filterAchievementsByVisible, getAchievementsByGroupName } from '~/utils/achievements';

import styles from './AchievementGroup.scss';

export interface AchievementGroup_Props extends AchievementsGroup {
  allAchievements: Array<Achievements>;
  accountAchievements: AchievementsAccountStatisticsAchievements;
  achievementsSortOrder: AchievementsSortOrder;
}

const AchievementGroup = (props: AchievementGroup_Props) => {
  const { id, title, allAchievements, accountAchievements, achievementsSortOrder } = props;
  const achievementSubGroups = [...(props.subgroups || [])].sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  });

  const achievementGroupSpoilerVisibleStatesKey = 'achievementGroupSpoilerVisibleStates';
  const getSpoilerVisibleState = (spoilerId: string): boolean => {
    const data = localStorage.getItem(achievementGroupSpoilerVisibleStatesKey);
    if (data === null) {
      return true;
    }
    const t = JSON.parse(data);
    return t[spoilerId] !== undefined ? t[spoilerId] : true;
  };

  const onSpoilerChangeVisible = (isVisible: boolean, spoilerId: string) => {
    const t = localStorage.getItem(achievementGroupSpoilerVisibleStatesKey);
    let data = JSON.parse(t);
    if (data == null) {
      data = {};
      data[spoilerId] = isVisible;
    } else {
      data[spoilerId] = isVisible;
    }
    localStorage.setItem(achievementGroupSpoilerVisibleStatesKey, JSON.stringify(data));
  };

  const groupAchievements = getAchievementsByGroupName(id, allAchievements);
  const achievements = filterAchievementsByVisible(groupAchievements, accountAchievements);
  const isAchievementsExist: boolean = achievements.length > 0;
  if (isAchievementsExist) {
    const spoilerId = `achievementGroupSpoiler-${id}`;
    const spoilerDefaultVisible = getSpoilerVisibleState(spoilerId);
    return (
      <div className={styles.groupRow}>
        <Spoiler
          id={spoilerId}
          title={title}
          titleClassName={styles.achievementGroupSpoilerTitle}
          onChange={onSpoilerChangeVisible}
          isDefaultVisible={spoilerDefaultVisible}
          content={achievementSubGroups.map((achievementSubGroup) => {
            return (
              <AchievementSubGroup
                {...achievementSubGroup}
                group_id={id}
                accountAchievements={accountAchievements}
                allAchievements={achievements}
                achievementsSortOrder={achievementsSortOrder}
                key={`AchievementSubGroup-${id}-${achievementSubGroup.id}`}
              />
            );
          })}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default AchievementGroup;
