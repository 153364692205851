import { Nav } from '@wg/wows-react-uikit';
import equal from 'fast-deep-equal/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { navigateToRoute } from '~/Actions/ActionNavigation';
import { DWH_EVENTS, NavigationPlaces, Pages } from '~/constants';
import { playButtonClickSound } from '~/helpers/audioApi';
import preloaded from '~/preloaded';
import { hideScrollThumb } from '~/scroll';
import { RootState, useAppDispatch, useAppSelector } from '~/store';
import dwhExport from '~/utils/dwhExport';
import { t } from '~/utils/localization';

import styles from './Menu.scss';

export interface MenuRoute {
  name: string;
  path: string;
  title: string;
}

const stateSelector = (state: RootState) => {
  return {
    spaId: state.app.spaId,
  };
};

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedPath, setSelectedPath] = useState('/');
  const dispatch = useAppDispatch();
  const { spaId } = useAppSelector(stateSelector, equal);

  const routes: Array<MenuRoute> = [
    {
      path: '/',
      name: Pages.SEARCH,
      title: t('Player Search'),
    },
  ];

  if (spaId) {
    routes.push({
      path: '/achievements',
      name: Pages.ACHIEVEMENTS,
      title: t('Achievements'),
    });
    routes.push({
      path: '/statistics',
      name: Pages.STATISTICKS,
      title: t('Summary'),
    });
  }

  const onRouteClick = (route: string) => {
    playButtonClickSound();

    const currentPage = routes.find((item) => item.path == route);
    if (currentPage) {
      const params: Record<string, string | boolean> = {
        name: currentPage.name,
        from: NavigationPlaces.MENU,
      };
      if (preloaded.account && currentPage.name === Pages.STATISTICKS) {
        params['profile_type'] = preloaded.account.id === +spaId || !spaId ? 'own' : 'other';
      }
      dwhExport.send(DWH_EVENTS.OPEN_PAGE, params);
    }

    dispatch(
      navigateToRoute(navigate, route, () => {
        setSelectedPath(route);
        window.scrollTo(0, 0);
        hideScrollThumb();
      }),
    );
  };

  useEffect(() => {
    const root = location.pathname.split('/')[1];
    setSelectedPath(`/${root}`);
  }, [location]);

  return (
    <div className={styles.menu}>
      <Nav onRouteClick={onRouteClick} root="" routes={routes} selectedPath={selectedPath} />
    </div>
  );
};

export default Menu;
