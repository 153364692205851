import { Button } from '@wg/wows-react-uikit';
import equal from 'fast-deep-equal/react';

import { inViteToClan } from '~/Actions/ActionApp';
import { DWH_EVENTS } from '~/constants';
import { RootState, useAppDispatch, useAppSelector } from '~/store';
import dwhExport from '~/utils/dwhExport';
import { t } from '~/utils/localization';

export interface ProfileState {
  isInviteSendMap: IsInviteSendMap;
  spaId?: string;
}

const stateSelector = (state: RootState) => {
  return {
    isInviteSendMap: state.app.isInviteSendMap,
    spaId: state.app.spaId,
  };
};

const InviteButton = () => {
  const dispatch = useAppDispatch();
  const { isInviteSendMap, spaId } = useAppSelector(stateSelector, equal);

  const disabled = spaId && Object.keys(isInviteSendMap).includes(spaId);

  const onClick = () => {
    dwhExport.send(DWH_EVENTS.INVITE);
    dispatch(inViteToClan());
  };

  return (
    <Button isFlat onClick={onClick} isDisabled={disabled}>
      {t('Invite to Clan')}
    </Button>
  );
};

export default InviteButton;
