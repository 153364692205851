import type { Action, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import persistMiddleware from '~/middlewares/persistMiddleware';

import appSlice from './appSlice';

const rootReducer = combineReducers({
  app: appSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(persistMiddleware);
  },
});

export type RootState = ReturnType<typeof rootReducer>;

export type IAppStore = typeof store;
export type IAppDispatch = ThunkDispatch<RootState, unknown, Action>;

export type IAppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
export type IAppAsyncThunk<ReturnType = void> = ThunkAction<Promise<ReturnType>, RootState, unknown, Action>;

export const useAppDispatch = useDispatch.withTypes<IAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector.withTypes<RootState>();
