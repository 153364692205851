import classNames from 'classnames';

import styles from './CookieButton.scss';

const isInGame = /WOWS\/1\.0/i.test(window.navigator && window.navigator.userAgent);

function CookieButton() {
  return isInGame ? null : <span id="ot-sdk-btn" className={classNames('ot-sdk-show-settings', styles.cookieButton)} />;
}

export default CookieButton;
