import classnames from 'classnames';
import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import { memo } from 'react';

import { RootState, useAppSelector } from '~/store';

import styles from './Flag.scss';

const nationsMapSelector = (state: RootState) => state.app.nationsMap;

export interface Flag_Props {
  nation: string;
  className?: string;
}

const Flag = (props: Flag_Props) => {
  const nationsMap = useAppSelector(nationsMapSelector, equal);
  const { nation } = props;
  const iconUrl = get(nationsMap, `[${nation}].icons.tiny`, undefined);

  return (
    <div
      className={classnames(styles.wrapper, props.className)}
      style={{
        backgroundImage: `url(${iconUrl})`,
      }}
    />
  );
};

export default memo(Flag, equal);
