import preloaded from '~/preloaded';

export const getLocalizationSafe = (
  object: Record<string, unknown> & { localization: Record<string, Record<string, string>> },
  string: string,
) => {
  const lString = object.localization?.[string];
  return lString?.[preloaded.settings.languageCode] || lString?.[preloaded.settings.languageCode] || '';
};

export const interpolate = function (
  fmt: string,
  obj: Record<string, string | number> | Array<string | number>,
  named = true,
  useBraces = false,
) {
  if (named) {
    let regex: RegExp, sliceNum: number;

    if (useBraces) {
      regex = /\{\w+\}/g;
      sliceNum = 1;
    } else {
      regex = /%\(\w+\)s/g;
      sliceNum = 2;
    }

    return fmt.replace(regex, function (match) {
      return String((obj as Record<string, string | number>)[match.slice(sliceNum, -sliceNum)]);
    });
  } else {
    const regex = useBraces ? /\{\}/g : /%s/g;

    return fmt.replace(regex, function () {
      return String((obj as Array<string | number>).shift());
    });
  }
};

const fallback = (str: string): string => str;

const _gettext = window.gettext || fallback;
const _ngettext = window.ngettext || fallback;

export const t = (str: string, params?: Record<string, string | number>): string => {
  if (params) {
    return interpolate(_gettext(str), params, true, false);
  }

  return _gettext(str);
};

export function ngettext(singular: string, plural: string, count: number | string, params?: any): string {
  if (typeof count === 'string') {
    count = parseInt(count);
  }
  let translation = _ngettext(singular, plural, count);
  if (params) {
    translation = interpolate(translation, params, true, false);
  }
  return translation;
}
