import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { playButtonClickSound } from '~/helpers/audioApi';

import styles from './DropPanel.scss';

export interface DropPanel_Props {
  fullwidth?: boolean;
  hasParentFocus?: boolean;
  selectedIndex?: number;
  itemHeight?: number;
  maxItemsCount?: number;
  items: Array<any>;
  isActive: boolean;
  renderItem: (index: number) => React.ReactNode;
  onEsc?: () => void;
  onSelect?: (index: number) => void;
  onIndexChange?: (index: number) => void;
}

const ITEM_HEIGHT_DEFAULT = 34;
const MAX_ITEM_COUNT_DEFAULT = 5;
const INITIAL_PANEL_WIDTH = 600;

const DropPanel = (props: DropPanel_Props) => {
  const scrollbarsRef = useRef<HTMLDivElement>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [panelWidth, setPanelWidth] = useState<number>(INITIAL_PANEL_WIDTH);
  const [isWidthCalculated, setIsWidthCalculated] = useState<boolean>(false);

  const {
    hasParentFocus,
    itemHeight,
    maxItemsCount,
    isActive,
    items,
    fullwidth,
    renderItem,
    onEsc,
    onSelect,
    onIndexChange,
  } = props;

  const ITEM_HEIGHT = itemHeight ? itemHeight : ITEM_HEIGHT_DEFAULT;
  const MAX_ITEM_COUNT = maxItemsCount ? maxItemsCount : MAX_ITEM_COUNT_DEFAULT;

  const onItemClick = (_: string, index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    playButtonClickSound();

    setSelectedIndex(index);
    if (onSelect) {
      onSelect(index);
    }
  };

  const onKeyDown = (
    e: KeyboardEvent | React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!isActive) {
      return;
    }
    let newIndex = hasParentFocus ? -1 : 0;
    const minIndex = hasParentFocus ? -1 : 0;
    if (e.keyCode === 38) {
      e.preventDefault();
      newIndex = selectedIndex - 1;
      if (newIndex < minIndex) {
        newIndex = items.length - 1;
      }
      setSelectedIndex(newIndex);
      if (onIndexChange) {
        onIndexChange(newIndex);
      }
      return;
    }

    if (e.keyCode === 40) {
      e.preventDefault();
      newIndex = selectedIndex + 1;
      if (newIndex > items.length - 1) {
        newIndex = minIndex;
      }
      setSelectedIndex(newIndex);
      if (onIndexChange) {
        onIndexChange(newIndex);
      }
      return;
    }

    if (e.keyCode === 13) {
      if (onSelect) {
        onSelect(selectedIndex);
      }
      return;
    }

    if (e.keyCode === 27) {
      e.preventDefault();
      if (onEsc) {
        onEsc();
      }
      return;
    }
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener('keydown', onKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [selectedIndex, isActive]);

  useEffect(() => {
    if (props.selectedIndex && props.selectedIndex !== selectedIndex) {
      setSelectedIndex(props.selectedIndex);
    }
  }, [props.selectedIndex]);

  const updateSize = (ref: HTMLDivElement) => {
    if (ref && !fullwidth) {
      const rect = ref.getBoundingClientRect();
      const width = parseInt(`${rect.width}`);
      const newWidth = width + 40;
      if (newWidth > panelWidth || !isWidthCalculated) {
        if (!isWidthCalculated) {
          setIsWidthCalculated(true);
        }
        setPanelWidth(newWidth);
      }
    }
  };

  return (
    <div
      ref={scrollbarsRef}
      style={{
        width: fullwidth ? `100%` : `${panelWidth}px`,
        maxHeight: ITEM_HEIGHT * MAX_ITEM_COUNT,
        height: items.length * ITEM_HEIGHT,
      }}
    >
      {items.map((item, index) => {
        const itemClass = classnames(styles.item, {
          [styles.itemSelected]: index === props.selectedIndex,
        });

        return (
          <div
            key={`item-${index}`}
            className={itemClass}
            onClick={onItemClick(item, index)}
            style={{
              height: ITEM_HEIGHT,
            }}
          >
            <div ref={fullwidth ? undefined : updateSize}>{renderItem(index)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DropPanel;
