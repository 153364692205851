import { TooltipBody } from '@wg/wows-react-uikit';
import equal from 'fast-deep-equal/react';
import get from 'lodash/get';
import { useEffect, useState } from 'react';

import BarChart from '~/Components/BarChart/BarChart';
import Flag from '~/Components/Flag/Flag';
import { Col, Container, Row } from '~/Components/Grid/Grid';
import PieChart from '~/Components/PieChart/PieChart';
import ShipClass from '~/Components/ShipClass/ShipClass';
import { RootState, useAppSelector } from '~/store';
import { getEmptyBarStats } from '~/utils';
import { formatShipLevel } from '~/utils/formatting';
import { ngettext, t } from '~/utils/localization';
import { isSupership } from '~/utils/ships';

import styles from './Charts.scss';

const stateSelector = (state: RootState) => {
  return {
    nationStatisticsMap: state.app.nationStatisticsMap,
    battleType: state.app.battleType,
    nationsMap: state.app.nationsMap,
    vehicleTypeStatisticsMap: state.app.vehicleTypeStatisticsMap,
    vehicleLevelStatisticsMap: state.app.vehicleLevelStatisticsMap,
    vehicleTypesMap: state.app.vehicleTypesMap,
    seasonPickerVisible: state.app.seasonPickerVisible,
    seasonNumber: state.app.seasonNumber,
  };
};

const vehicleColorsMap = {
  aircarrier: '#f3bd7f',
  battleship: '#d14842',
  cruiser: '#3497da',
  destroyer: '#cfd4d8',
  submarine: '#808b8d',
};

const Charts = () => {
  const {
    battleType,
    nationStatisticsMap,
    nationsMap,
    vehicleTypeStatisticsMap,
    vehicleLevelStatisticsMap,
    vehicleTypesMap,
    seasonPickerVisible,
    seasonNumber,
  } = useAppSelector(stateSelector, equal);

  const [pieChartDataNations, setPieChartDataNations] = useState([]);
  const [pieChartDatavehicleType, setPieChartDataVehicleType] = useState([]);
  const [barChartData, setBarChartData] = useState([]);

  useEffect(() => {
    const nationsdata = [];
    for (const key in nationStatisticsMap) {
      let val = nationStatisticsMap?.[key]?.[battleType] || 0;
      if (seasonPickerVisible) {
        val = get(nationStatisticsMap, `${key}.seasons.${seasonNumber}.${battleType}`, 0) as number;
      }
      if (val > 0) {
        const color = nationsMap[key].color.replace('#bffcc00', '#ffcc00');
        nationsdata.push({
          value: val,
          title: nationsMap[key].title,
          color: color,
          name: key,
        });
      }
    }
    setPieChartDataNations(nationsdata);

    const vehicleTypeData = [];
    for (const key in vehicleTypeStatisticsMap) {
      let val = get(vehicleTypeStatisticsMap, `${key}.${battleType}`, 0) as number;
      if (seasonPickerVisible) {
        val = get(vehicleTypeStatisticsMap, `${key}.seasons.${seasonNumber}.${battleType}`, 0) as number;
      }

      if (val > 0) {
        vehicleTypeData.push({
          value: val,
          title: key,
          // @ts-ignore
          color: vehicleColorsMap[key],
        });
      }
    }
    setPieChartDataVehicleType(vehicleTypeData);

    const barChartData: Array<any> = [];

    const emptyStats = {
      ...getEmptyBarStats(),
      ...vehicleLevelStatisticsMap,
    };

    for (const key in emptyStats) {
      let val = get(vehicleLevelStatisticsMap, `${key}.${battleType}`, 0);
      if (seasonPickerVisible) {
        val = get(vehicleLevelStatisticsMap, `${key}.seasons.${seasonNumber}.${battleType}`, 0);
      }
      const shipLevel = parseInt(key, 10);
      barChartData.push({
        value: val,
        label: formatShipLevel(shipLevel),
        shipLevel,
        fontSize: isSupership(shipLevel) ? 16 : 14,
      });
    }

    setBarChartData(barChartData);
  }, [battleType, nationStatisticsMap, vehicleTypeStatisticsMap, seasonPickerVisible, seasonNumber]);

  const renderPieChartNationItem = (index: number) => {
    const name = pieChartDataNations[index].name;
    return (
      <div className={styles.legendItem}>
        <Flag nation={name} />
      </div>
    );
  };

  const renderPieChartNationItemTooltip = (index: number) => {
    const nation = pieChartDataNations[index].title;
    return (
      <TooltipBody>
        {ngettext(
          '%(nation)s: %(battles)s battles',
          '%(nation)s: %(battles)s battles',
          pieChartDataNations[index].value,
          {
            battles: pieChartDataNations[index].value,
            nation: nation,
          },
        )}
      </TooltipBody>
    );
  };

  const renderPieChartShipItem = (index: number) => {
    return (
      <div className={styles.legendItem}>
        <ShipClass type={pieChartDatavehicleType[index].title} />
      </div>
    );
  };

  const renderPieChartShipItemTooltip = (index: number) => {
    const title = vehicleTypesMap[pieChartDatavehicleType[index].title].title;

    return (
      <TooltipBody>
        {ngettext(
          '%(vehicleType)s: %(battles)s battles',
          '%(vehicleType)s: %(battles)s battles',
          pieChartDatavehicleType[index].value,
          {
            battles: pieChartDatavehicleType[index].value,
            vehicleType: title,
          },
        )}
      </TooltipBody>
    );
  };

  const renderBarChartItemTooltip = (index: number) => {
    if (barChartData[index].value === 0) {
      return <TooltipBody>{t('No battles')}</TooltipBody>;
    }
    return isSupership(barChartData[index].shipLevel) ? (
      <TooltipBody>
        {ngettext(
          '%(battles)s battles with superships',
          '%(battles)s battles with superships',
          barChartData[index].value,
          {
            battles: barChartData[index].value,
            level: barChartData[index].label,
          },
        )}
      </TooltipBody>
    ) : (
      <TooltipBody>
        {ngettext(
          '%(battles)s battles playing Tier %(level)s ships',
          '%(battles)s battles playing Tier %(level)s ships',
          barChartData[index].value,
          {
            battles: barChartData[index].value,
            level: barChartData[index].label,
          },
        )}
      </TooltipBody>
    );
  };

  return (
    <Container className={styles.wrapper}>
      <Row>
        <Col size={4}>
          <div className={styles.title}>{t('Nations')}</div>
          <div className={styles.chart}>
            <PieChart
              renderLegendItem={renderPieChartNationItem}
              renderItemTooltip={renderPieChartNationItemTooltip}
              data={pieChartDataNations}
            />
          </div>
        </Col>
        <Col size={4}>
          <div className={styles.title}>{t('Types')}</div>
          <div className={styles.chart}>
            <PieChart
              renderLegendItem={renderPieChartShipItem}
              renderItemTooltip={renderPieChartShipItemTooltip}
              data={pieChartDatavehicleType}
            />
          </div>
        </Col>
        <Col size={4}>
          <div className={styles.title}>{t('Tiers')}</div>
          <div className={styles.chart}>
            <BarChart renderItemTooltip={renderBarChartItemTooltip} data={barChartData} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Charts;
