import { Outlet } from 'react-router-dom';

import { Container } from '~/Components/Grid/Grid';
import Init from '~/Components/Init/Init';
import Menu from '~/Components/Menu/Menu';
import MenuSpacer from '~/Components/Menu/MenuSpacer';
import Spinner from '~/Components/Spinner/Spinner';

const Root = () => {
  return (
    <>
      <MenuSpacer>
        <Container>
          <Init />
          <Outlet />
        </Container>
      </MenuSpacer>
      <Menu />
      <Spinner />
    </>
  );
};

export default Root;
